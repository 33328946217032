import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { useTranslation } from 'react-i18next';
import '../App.css';
import './Register.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import icons for password toggle

const Register = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState('male');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [countryCode, setCountryCode] = useState('+49'); // Default country code
  const [phoneNumber, setPhoneNumber] = useState(''); const [numberOfPersons, setNumberOfPersons] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [gdprCompliance, setGdprCompliance] = useState(false); // GDPR compliance state

  const [validationErrors, setValidationErrors] = useState({});
  const [emailInUse, setEmailInUse] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  const { register } = useContext(AuthContext);
  const navigate = useNavigate();
  const countryCodes = [
    { code: '+93', name: 'Afghanistan' },
    { code: '+355', name: 'Albania' },
    { code: '+213', name: 'Algeria' },
    { code: '+376', name: 'Andorra' },
    { code: '+244', name: 'Angola' },
    { code: '+54', name: 'Argentina' },
    { code: '+61', name: 'Australia' },
    { code: '+43', name: 'Austria' },
    { code: '+994', name: 'Azerbaijan' },
    { code: '+973', name: 'Bahrain' },
    { code: '+880', name: 'Bangladesh' },
    { code: '+375', name: 'Belarus' },
    { code: '+32', name: 'Belgium' },
    { code: '+229', name: 'Benin' },
    { code: '+975', name: 'Bhutan' },
    { code: '+591', name: 'Bolivia' },
    { code: '+387', name: 'Bosnia and Herzegovina' },
    { code: '+267', name: 'Botswana' },
    { code: '+55', name: 'Brazil' },
    { code: '+673', name: 'Brunei' },
    { code: '+359', name: 'Bulgaria' },
    { code: '+226', name: 'Burkina Faso' },
    { code: '+95', name: 'Myanmar' },
    { code: '+257', name: 'Burundi' },
    { code: '+855', name: 'Cambodia' },
    { code: '+237', name: 'Cameroon' },
    { code: '+1', name: 'Canada' },
    { code: '+238', name: 'Cape Verde' },
    { code: '+236', name: 'Central African Republic' },
    { code: '+235', name: 'Chad' },
    { code: '+56', name: 'Chile' },
    { code: '+86', name: 'China' },
    { code: '+57', name: 'Colombia' },
    { code: '+269', name: 'Comoros' },
    { code: '+242', name: 'Congo' },
    { code: '+243', name: 'Congo (Democratic Republic)' },
    { code: '+682', name: 'Cook Islands' },
    { code: '+506', name: 'Costa Rica' },
    { code: '+385', name: 'Croatia' },
    { code: '+53', name: 'Cuba' },
    { code: '+357', name: 'Cyprus' },
    { code: '+420', name: 'Czech Republic' },
    { code: '+45', name: 'Denmark' },
    { code: '+253', name: 'Djibouti' },
    { code: '+1', name: 'Dominica' },
    { code: '+1', name: 'Dominican Republic' },
    { code: '+593', name: 'Ecuador' },
    { code: '+20', name: 'Egypt' },
    { code: '+503', name: 'El Salvador' },
    { code: '+240', name: 'Equatorial Guinea' },
    { code: '+291', name: 'Eritrea' },
    { code: '+372', name: 'Estonia' },
    { code: '+251', name: 'Ethiopia' },
    { code: '+679', name: 'Fiji' },
    { code: '+358', name: 'Finland' },
    { code: '+33', name: 'France' },
    { code: '+241', name: 'Gabon' },
    { code: '+220', name: 'Gambia' },
    { code: '+995', name: 'Georgia' },
    { code: '+49', name: 'Germany' },
    { code: '+233', name: 'Ghana' },
    { code: '+30', name: 'Greece' },
    { code: '+502', name: 'Guatemala' },
    { code: '+224', name: 'Guinea' },
    { code: '+245', name: 'Guinea-Bissau' },
    { code: '+592', name: 'Guyana' },
    { code: '+509', name: 'Haiti' },
    { code: '+504', name: 'Honduras' },
    { code: '+36', name: 'Hungary' },
    { code: '+354', name: 'Iceland' },
    { code: '+91', name: 'India' },
    { code: '+62', name: 'Indonesia' },
    { code: '+98', name: 'Iran' },
    { code: '+964', name: 'Iraq' },
    { code: '+353', name: 'Ireland' },
    { code: '+972', name: 'Israel' },
    { code: '+39', name: 'Italy' },
    { code: '+81', name: 'Japan' },
    { code: '+962', name: 'Jordan' },
    { code: '+7', name: 'Kazakhstan' },
    { code: '+254', name: 'Kenya' },
    { code: '+686', name: 'Kiribati' },
    { code: '+965', name: 'Kuwait' },
    { code: '+996', name: 'Kyrgyzstan' },
    { code: '+856', name: 'Laos' },
    { code: '+371', name: 'Latvia' },
    { code: '+961', name: 'Lebanon' },
    { code: '+266', name: 'Lesotho' },
    { code: '+231', name: 'Liberia' },
    { code: '+218', name: 'Libya' },
    { code: '+423', name: 'Liechtenstein' },
    { code: '+370', name: 'Lithuania' },
    { code: '+352', name: 'Luxembourg' },
    { code: '+389', name: 'Macedonia' },
    { code: '+261', name: 'Madagascar' },
    { code: '+265', name: 'Malawi' },
    { code: '+60', name: 'Malaysia' },
    { code: '+960', name: 'Maldives' },
    { code: '+223', name: 'Mali' },
    { code: '+356', name: 'Malta' },
    { code: '+692', name: 'Marshall Islands' },
    { code: '+222', name: 'Mauritania' },
    { code: '+230', name: 'Mauritius' },
    { code: '+52', name: 'Mexico' },
    { code: '+691', name: 'Micronesia' },
    { code: '+373', name: 'Moldova' },
    { code: '+377', name: 'Monaco' },
    { code: '+976', name: 'Mongolia' },
    { code: '+382', name: 'Montenegro' },
    { code: '+212', name: 'Morocco' },
    { code: '+258', name: 'Mozambique' },
    { code: '+95', name: 'Myanmar' },
    { code: '+264', name: 'Namibia' },
    { code: '+674', name: 'Nauru' },
    { code: '+977', name: 'Nepal' },
    { code: '+31', name: 'Netherlands' },
    { code: '+64', name: 'New Zealand' },
    { code: '+505', name: 'Nicaragua' },
    { code: '+227', name: 'Niger' },
    { code: '+234', name: 'Nigeria' },
    { code: '+47', name: 'Norway' },
    { code: '+968', name: 'Oman' },
    { code: '+92', name: 'Pakistan' },
    { code: '+680', name: 'Palau' },
    { code: '+507', name: 'Panama' },
    { code: '+675', name: 'Papua New Guinea' },
    { code: '+595', name: 'Paraguay' },
    { code: '+51', name: 'Peru' },
    { code: '+63', name: 'Philippines' },
    { code: '+48', name: 'Poland' },
    { code: '+351', name: 'Portugal' },
    { code: '+974', name: 'Qatar' },
    { code: '+40', name: 'Romania' },
    { code: '+7', name: 'Russia' },
    { code: '+250', name: 'Rwanda' },
    { code: '+685', name: 'Samoa' },
    // (Add remaining as required...)
  ];


  const validateForm = () => {
    const errors = {};
    const today = new Date();

    if (!firstName || !/^[A-Za-z]{2,}$/.test(firstName)) {
      errors.firstName = t('firstNameInvalid');
    }
    if (!lastName || !/^[A-Za-z]{2,}$/.test(lastName)) {
      errors.lastName = t('lastNameInvalid');
    }

    if (!birthDate) {
      errors.birthDate = t('birthDateRequired');
    } else if (new Date(birthDate) >= today) {
      errors.birthDate = t('birthDateInPast');
    }

    if (!email || !/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
      errors.email = t('emailInvalid');
    }
    if (!phoneNumber || !/^\d{7,15}$/.test(phoneNumber)) {
      errors.phoneNumber = t('invalidPhoneNumber');
    }

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@/,.!%*?&-])[A-Za-z\d@/,.!%*?&-]{8,}$/;
    if (!password || !passwordPattern.test(password)) {
      errors.password = t('passwordInvalid');
    }

    if (!numberOfPersons || isNaN(numberOfPersons) || numberOfPersons < 1) {
      errors.numberOfPersons = t('numberOfPersonsMin');
    }
    if (!gdprCompliance) {
      errors.gdprCompliance = t('gdprRequired');
    }

    return errors;
  };

  const checkPasswordCriteria = (password) => {
    setPasswordCriteria({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[@/,.!%*?&-]/.test(password),
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setLoading(false);
      return;
    }

    const formData = {
      first_name: firstName,
      last_name: lastName,
      birth_date: birthDate,
      gender: gender,
      email: email,
      password: password,
      phone_number: `${countryCode}-${phoneNumber}`, // Combine country code and phone number
      number_of_persons: numberOfPersons,
      message: message,
      gdpr_compliance: gdprCompliance,
    };

    const registrationResult = await register(formData);

    if (registrationResult === "Email already registered") {
      setEmailInUse(true);
      setValidationErrors({ ...validationErrors, email: t('emailAlreadyRegistered') });
      setLoading(false);
    } else if (registrationResult === true) {
      navigate('/login', { state: { message: t('checkYourEmail') } });
    } else {
      console.log(t('registrationFailed'));
    }

    setLoading(false);
  };

  return (
    <div className="profile-container">
      <form onSubmit={handleRegister} className="mt-4">
        <h2>{t('register')}</h2>

        <div className="mb-3">
          <label className="form-label">{t('firstName')}*:</label>
          <input
            type="text"
            className={`form-control ${validationErrors.firstName ? 'is-invalid' : ''}`}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          {validationErrors.firstName && <div className="invalid-feedback">{validationErrors.firstName}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('lastName')}*:</label>
          <input
            type="text"
            className={`form-control ${validationErrors.lastName ? 'is-invalid' : ''}`}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          {validationErrors.lastName && <div className="invalid-feedback">{validationErrors.lastName}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('birthDate')}*:</label>
          <input
            type="date"
            className={`form-control ${validationErrors.birthDate ? 'is-invalid' : ''}`}
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            required
          />
          {validationErrors.birthDate && <div className="invalid-feedback">{validationErrors.birthDate}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('gender')}*:</label>
          <select className="form-control" value={gender} onChange={(e) => setGender(e.target.value)} required>
            <option value="male">{t('male')}</option>
            <option value="female">{t('female')}</option>
            <option value="other">{t('other')}</option>
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">{t('email')}*:</label>
          <input
            type="email"
            className={`form-control ${validationErrors.email || emailInUse ? 'is-invalid' : ''}`}
            value={email}
            onChange={(e) => { setEmail(e.target.value); setEmailInUse(false); }}
            required
          />
          {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
          {emailInUse && <div className="invalid-feedback">{t('emailAlreadyRegistered')}</div>}
        </div>

        <div className="mb-3">
          <label className="form-label">{t('password')}*:</label>
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className={`form-control ${validationErrors.password ? 'is-invalid' : ''}`}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPasswordCriteria(e.target.value);
              }}
              required
            />
            <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {validationErrors.password && <div className="invalid-feedback">{validationErrors.password}</div>}
          <div className="form-text">{t('passwordCriteria')}</div>
          <ul className="text-danger">
            <li className={passwordCriteria.length ? 'text-success' : ''}>{t('passwordMinLength')}</li>
            <li className={passwordCriteria.uppercase ? 'text-success' : ''}>{t('passwordUppercase')}</li>
            <li className={passwordCriteria.lowercase ? 'text-success' : ''}>{t('passwordLowercase')}</li>
            <li className={passwordCriteria.number ? 'text-success' : ''}>{t('passwordNumber')}</li>
            <li className={passwordCriteria.special ? 'text-success' : ''}>{t('passwordSpecial')}</li>
          </ul>
        </div>


        <div className="mb-3">
          <label className="form-label">{t('numberOfPersons')}*:</label>
          <input
            type="number"
            className={`form-control ${validationErrors.numberOfPersons ? 'is-invalid' : ''}`}
            value={numberOfPersons}
            onChange={(e) => setNumberOfPersons(e.target.value)}
            required
          />
          {validationErrors.numberOfPersons && (
            <div className="invalid-feedback">{validationErrors.numberOfPersons}</div>
          )}
        </div>

        {/* Phone Number Input with Country Code */}
        <div className="mb-3">
          <label className="form-label">{t('phoneNumber')}*:</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <select
                className="form-select country-code-select"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                {countryCodes.map((code) => (
                  <option key={code.code} value={code.code}>
                    {code.name} ({code.code})
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              className={`form-control phone-number-input ${validationErrors.phoneNumber ? 'is-invalid' : ''}`}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            {validationErrors.phoneNumber && <div className="invalid-feedback">{validationErrors.phoneNumber}</div>}
          </div>
        </div>


        <div className="mb-3">
          <label className="form-label">{t('message')} ({t('optional')})</label>
          <textarea
            className={`form-control ${validationErrors.message ? 'is-invalid' : ''}`}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        {/* GDPR Compliance Checkbox */}
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="gdprCompliance"
            checked={gdprCompliance}
            onChange={(e) => setGdprCompliance(e.target.checked)}
            required
          />
          <label className="form-check-label" htmlFor="gdprCompliance">
            <span>
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="gdpr-link"
              >
                {t('gdprAgreement')}
              </a>
            </span>
          </label>
          {validationErrors.gdprCompliance && <div className="invalid-feedback">{validationErrors.gdprCompliance}</div>}
        </div>

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : t('register')}
        </button>
      </form>
    </div>
  );
};

export default Register;
