import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import { useTranslation } from 'react-i18next';
import api from '../sharedComponents/api';

const Profile = () => {
    const { t } = useTranslation();
    const [, setProfile] = useState({});
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        birth_date: '',
        gender: '',
        email: '',
        country_code: '+49', // Default to Germany
        phoneNumber: '',
        number_of_persons: '',
        message: '',
        ishandycap: 'No',
        haswbs: 'No',
        wbs_expire_date: '',
        wbs_number_of_rooms: '',
        wbs_type: '',
        last_login: '' // New field for displaying last login
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const countryCodes = [
        { code: '+93', name: 'Afghanistan' },
        { code: '+355', name: 'Albania' },
        { code: '+213', name: 'Algeria' },
        { code: '+376', name: 'Andorra' },
        { code: '+244', name: 'Angola' },
        { code: '+54', name: 'Argentina' },
        { code: '+61', name: 'Australia' },
        { code: '+43', name: 'Austria' },
        { code: '+994', name: 'Azerbaijan' },
        { code: '+973', name: 'Bahrain' },
        { code: '+880', name: 'Bangladesh' },
        { code: '+375', name: 'Belarus' },
        { code: '+32', name: 'Belgium' },
        { code: '+229', name: 'Benin' },
        { code: '+975', name: 'Bhutan' },
        { code: '+591', name: 'Bolivia' },
        { code: '+387', name: 'Bosnia and Herzegovina' },
        { code: '+267', name: 'Botswana' },
        { code: '+55', name: 'Brazil' },
        { code: '+673', name: 'Brunei' },
        { code: '+359', name: 'Bulgaria' },
        { code: '+226', name: 'Burkina Faso' },
        { code: '+95', name: 'Myanmar' },
        { code: '+257', name: 'Burundi' },
        { code: '+855', name: 'Cambodia' },
        { code: '+237', name: 'Cameroon' },
        { code: '+1', name: 'Canada' },
        { code: '+238', name: 'Cape Verde' },
        { code: '+236', name: 'Central African Republic' },
        { code: '+235', name: 'Chad' },
        { code: '+56', name: 'Chile' },
        { code: '+86', name: 'China' },
        { code: '+57', name: 'Colombia' },
        { code: '+269', name: 'Comoros' },
        { code: '+242', name: 'Congo' },
        { code: '+243', name: 'Congo (Democratic Republic)' },
        { code: '+682', name: 'Cook Islands' },
        { code: '+506', name: 'Costa Rica' },
        { code: '+385', name: 'Croatia' },
        { code: '+53', name: 'Cuba' },
        { code: '+357', name: 'Cyprus' },
        { code: '+420', name: 'Czech Republic' },
        { code: '+45', name: 'Denmark' },
        { code: '+253', name: 'Djibouti' },
        { code: '+1', name: 'Dominica' },
        { code: '+1', name: 'Dominican Republic' },
        { code: '+593', name: 'Ecuador' },
        { code: '+20', name: 'Egypt' },
        { code: '+503', name: 'El Salvador' },
        { code: '+240', name: 'Equatorial Guinea' },
        { code: '+291', name: 'Eritrea' },
        { code: '+372', name: 'Estonia' },
        { code: '+251', name: 'Ethiopia' },
        { code: '+679', name: 'Fiji' },
        { code: '+358', name: 'Finland' },
        { code: '+33', name: 'France' },
        { code: '+241', name: 'Gabon' },
        { code: '+220', name: 'Gambia' },
        { code: '+995', name: 'Georgia' },
        { code: '+49', name: 'Germany' },
        { code: '+233', name: 'Ghana' },
        { code: '+30', name: 'Greece' },
        { code: '+502', name: 'Guatemala' },
        { code: '+224', name: 'Guinea' },
        { code: '+245', name: 'Guinea-Bissau' },
        { code: '+592', name: 'Guyana' },
        { code: '+509', name: 'Haiti' },
        { code: '+504', name: 'Honduras' },
        { code: '+36', name: 'Hungary' },
        { code: '+354', name: 'Iceland' },
        { code: '+91', name: 'India' },
        { code: '+62', name: 'Indonesia' },
        { code: '+98', name: 'Iran' },
        { code: '+964', name: 'Iraq' },
        { code: '+353', name: 'Ireland' },
        { code: '+972', name: 'Israel' },
        { code: '+39', name: 'Italy' },
        { code: '+81', name: 'Japan' },
        { code: '+962', name: 'Jordan' },
        { code: '+7', name: 'Kazakhstan' },
        { code: '+254', name: 'Kenya' },
        { code: '+686', name: 'Kiribati' },
        { code: '+965', name: 'Kuwait' },
        { code: '+996', name: 'Kyrgyzstan' },
        { code: '+856', name: 'Laos' },
        { code: '+371', name: 'Latvia' },
        { code: '+961', name: 'Lebanon' },
        { code: '+266', name: 'Lesotho' },
        { code: '+231', name: 'Liberia' },
        { code: '+218', name: 'Libya' },
        { code: '+423', name: 'Liechtenstein' },
        { code: '+370', name: 'Lithuania' },
        { code: '+352', name: 'Luxembourg' },
        { code: '+389', name: 'Macedonia' },
        { code: '+261', name: 'Madagascar' },
        { code: '+265', name: 'Malawi' },
        { code: '+60', name: 'Malaysia' },
        { code: '+960', name: 'Maldives' },
        { code: '+223', name: 'Mali' },
        { code: '+356', name: 'Malta' },
        { code: '+692', name: 'Marshall Islands' },
        { code: '+222', name: 'Mauritania' },
        { code: '+230', name: 'Mauritius' },
        { code: '+52', name: 'Mexico' },
        { code: '+691', name: 'Micronesia' },
        { code: '+373', name: 'Moldova' },
        { code: '+377', name: 'Monaco' },
        { code: '+976', name: 'Mongolia' },
        { code: '+382', name: 'Montenegro' },
        { code: '+212', name: 'Morocco' },
        { code: '+258', name: 'Mozambique' },
        { code: '+95', name: 'Myanmar' },
        { code: '+264', name: 'Namibia' },
        { code: '+674', name: 'Nauru' },
        { code: '+977', name: 'Nepal' },
        { code: '+31', name: 'Netherlands' },
        { code: '+64', name: 'New Zealand' },
        { code: '+505', name: 'Nicaragua' },
        { code: '+227', name: 'Niger' },
        { code: '+234', name: 'Nigeria' },
        { code: '+47', name: 'Norway' },
        { code: '+968', name: 'Oman' },
        { code: '+92', name: 'Pakistan' },
        { code: '+680', name: 'Palau' },
        { code: '+507', name: 'Panama' },
        { code: '+675', name: 'Papua New Guinea' },
        { code: '+595', name: 'Paraguay' },
        { code: '+51', name: 'Peru' },
        { code: '+63', name: 'Philippines' },
        { code: '+48', name: 'Poland' },
        { code: '+351', name: 'Portugal' },
        { code: '+974', name: 'Qatar' },
        { code: '+40', name: 'Romania' },
        { code: '+7', name: 'Russia' },
        { code: '+250', name: 'Rwanda' },
        { code: '+685', name: 'Samoa' },
        // (Add remaining as required...)
    ];

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await api.get(`/profile`);
                console.log("Profile Data:", response.data); // Debug log the full response data

                // Split the phone number into country code and phone number
                const fullPhoneNumber = response.data.phone_number || '';
                const [countryCode, ...phoneParts] = fullPhoneNumber.split('-'); // Split on the `-` separator
                const phoneNumber = phoneParts.join(''); // Rejoin the rest of the parts as the phone number
                
                // Skip leading 0 if present
                if (phoneNumber.startsWith('0')) {
                    phoneNumber = phoneNumber.substring(1);
                }
                
                console.log("Extracted Country Code:", countryCode); // Debug log
                console.log("Extracted Phone Number:", phoneNumber); // Debug log

                // Update the form data state
                setFormData({
                    ...response.data,
                    country_code: countryCode || '+49', // Default to '+49' if no country code
                    phone_number: phoneNumber || '', // Ensure phone number is clean
                    ishandycap: response.data.ishandycap ? 'Yes' : 'No',
                    haswbs: response.data.haswbs ? 'Yes' : 'No',
                    wbs_expire_date: response.data.wbs_expire_date || '',
                    last_login: response.data.last_login || ''
                });

                console.log("Form Data After Splitting Phone Number:", {
                    ...response.data,
                    country_code: countryCode,
                    phone_number: phoneNumber
                }); // Debug: Log final form data
            } catch (error) {
                console.error(t('errorFetchingProfile'), error); // Log any errors
            }
        };

        fetchProfile();
    }, [t]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        let cleanValue = value;

        // Special handling for phone_number
        if (name === 'phone_number') {
            cleanValue = value.replace(/\D/g, ''); // Remove all non-numeric characters
        }

        // Update the formData state
        setFormData({
            ...formData,
            [name]: type === 'radio' ? (checked ? value : formData[name]) : cleanValue
        });

        // Clear the specific error for the field being updated
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleDeleteAccount = async () => {
        if (window.confirm(t('confirmDeleteAccount'))) {
            try {
                await api.delete(`/profile`);
                alert(t('accountDeleted'));
                navigate('/'); // Redirect after deletion
            } catch (error) {
                console.error(t('errorDeletingAccount'), error);
            }
        }
    };


    const validateForm = () => {
        const newErrors = {};
        const today = new Date();

        // Validation rules
        if (formData.first_name.trim().length < 2) {
            newErrors.first_name = t('firstNameValidation');
        }
        if (formData.last_name.trim().length < 2) {
            newErrors.last_name = t('lastNameValidation');
        }
        if (!/^\d{7,15}$/.test(formData.phone_number)) {
            newErrors.phone_number = t('invalidPhoneNumber');
        }
        if (formData.number_of_persons <= 0) {
            newErrors.number_of_persons = t('numberOfPersonsValidation');
        }
        if (formData.birth_date && new Date(formData.birth_date) >= today) {
            newErrors.birth_date = t('invalidBirthDate');
        }
        if (formData.haswbs === 'Yes') {
            if (!formData.wbs_expire_date || new Date(formData.wbs_expire_date) < today) {
                newErrors.wbs_expire_date = t('invalidWBSExpireDate');
            }
            if (!formData.wbs_number_of_rooms || formData.wbs_number_of_rooms <= 0) {
                newErrors.wbs_number_of_rooms = t('invalidWBSNumberOfRooms');
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        // Ensure the phone number doesn't already have the country code
        let cleanPhoneNumber = formData.phone_number;

        // Strip any leading country code if present
        if (cleanPhoneNumber.startsWith(formData.country_code)) {
            cleanPhoneNumber = cleanPhoneNumber.replace(formData.country_code, '');
        }

        const dataToSend = {
            ...formData,
            phone_number: `${formData.country_code}-${cleanPhoneNumber}`, // Combine properly
            ishandycap: formData.ishandycap === 'Yes',
            haswbs: formData.haswbs === 'Yes',
            number_of_persons: parseInt(formData.number_of_persons, 10),
            wbs_expire_date: formData.wbs_expire_date === '' ? null : formData.wbs_expire_date,
            wbs_number_of_rooms: formData.wbs_number_of_rooms === '' ? null : parseInt(formData.wbs_number_of_rooms, 10),
            wbs_type: formData.wbs_type === '' ? null : formData.wbs_type
        };

        try {
            await api.put(`/profile`, dataToSend); // Use custom api instance here
            setProfile(dataToSend);
            alert(t('profileUpdated'));
        } catch (error) {
            // Check if backend sent validation errors
            if (error.response && error.response.data.message) {
                const errorMessage = error.response.data.message;

                // Map backend error messages to the frontend fields
                if (errorMessage.includes('phone number')) {
                    setErrors({ ...errors, phone_number: t('invalidPhoneNumber') });
                } else if (errorMessage.includes('first name')) {
                    setErrors({ ...errors, first_name: t('firstNameValidation') });
                } else if (errorMessage.includes('last name')) {
                    setErrors({ ...errors, last_name: t('lastNameValidation') });
                } else if (errorMessage.includes('birth date')) {
                    setErrors({ ...errors, birth_date: t('invalidBirthDate') });
                } else {
                    // General error fallback
                    alert(t('updateFailed'));
                }
            } else {
                console.error(error);
                alert(t('updateFailed'));
            }
        }
    };

    return (
        <div className="container">
            <div className="profile-container">
                <h2>{t('profile')}</h2>
                {(
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>{t('firstName')}*:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('lastName')}*:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('birthDate')}*:</label>
                            <input
                                type="date"
                                className={`form-control ${errors.birth_date ? 'is-invalid' : ''}`}
                                name="birth_date"
                                value={formData.birth_date}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.birth_date && <div className="invalid-feedback">{errors.birth_date}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('gender')}*:</label>
                            <select
                                className="form-control"
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="male">{t('male')}</option>
                                <option value="female">{t('female')}</option>
                                <option value="other">{t('other')}</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>{t('email')}*:</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                readOnly
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('phoneNumber')}:</label>
                            <div className="d-flex">
                                <select
                                    name="country_code"
                                    className="form-select country-code-select"
                                    value={formData.country_code} // Bind the value to `formData.country_code`
                                    onChange={handleInputChange} // Update state when the user changes the dropdown
                                >
                                    {countryCodes.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {country.name} ({country.code})
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="text"
                                    name="phone_number"
                                    className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                                    placeholder={t('phoneNumber')}
                                    value={formData.phone_number} // Bind the phone number input to `formData.phone_number`
                                    onChange={handleInputChange}
                                />
                                {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
                            </div>
                        </div>

                        <div className="form-group">
                            <label>{t('numberOfPersons')}*:</label>
                            <input
                                type="number"
                                className={`form-control ${errors.number_of_persons ? 'is-invalid' : ''}`}
                                name="number_of_persons"
                                value={formData.number_of_persons}
                                onChange={handleInputChange}
                                required
                            />
                            {errors.number_of_persons && <div className="invalid-feedback">{errors.number_of_persons}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('message')}:</label>
                            <textarea
                                className="form-control"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>{t('handicap')}*:</label>
                            <div>
                                <label className="form-check-label me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="ishandycap"
                                        value="Yes"
                                        checked={formData.ishandycap === 'Yes'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('yes')}
                                </label>
                                <label className="form-check-label">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="ishandycap"
                                        value="No"
                                        checked={formData.ishandycap === 'No'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('no')}
                                </label>
                            </div>
                            {errors.ishandycap && <div className="invalid-feedback">{errors.ishandycap}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t('hasWBS')}*:</label>
                            <div>
                                <label className="form-check-label me-3">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="haswbs"
                                        value="Yes"
                                        checked={formData.haswbs === 'Yes'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('yes')}
                                </label>
                                <label className="form-check-label">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="haswbs"
                                        value="No"
                                        checked={formData.haswbs === 'No'}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {t('no')}
                                </label>
                            </div>
                            {errors.haswbs && <div className="invalid-feedback">{errors.haswbs}</div>}
                        </div>
                        {formData.haswbs === 'Yes' && (
                            <>
                                <div className="form-group">
                                    <label>{t('wbsExpireDate')}*:</label>
                                    <input
                                        type="date"
                                        className={`form-control ${errors.wbs_expire_date ? 'is-invalid' : ''}`}
                                        name="wbs_expire_date"
                                        value={formData.wbs_expire_date}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.wbs_expire_date && <div className="invalid-feedback">{errors.wbs_expire_date}</div>}
                                </div>
                                <div className="form-group">
                                    <label>{t('wbsNumberOfRooms')}*:</label>
                                    <input
                                        type="number"
                                        className={`form-control ${errors.wbs_number_of_rooms ? 'is-invalid' : ''}`}
                                        name="wbs_number_of_rooms"
                                        value={formData.wbs_number_of_rooms}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.wbs_number_of_rooms && <div className="invalid-feedback">{errors.wbs_number_of_rooms}</div>}
                                </div>
                                <div className="form-group">
                                    <label>{t('wbsType')}*:</label>
                                    <select
                                        className="form-control"
                                        name="wbs_type"
                                        value={formData.wbs_type}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="WBS140">WBS 140</option>
                                        <option value="WBS160">WBS 160</option>
                                        <option value="WBS180">WBS 180</option>
                                        <option value="WBS220">WBS 220</option>
                                    </select>
                                </div>
                            </>
                        )}
                        <div className="form-group">
                            <label>{t('lastLogin')}:</label>
                            <input
                                type="text"
                                className="form-control"
                                name="last_login"
                                value={formData.last_login}
                                readOnly
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">{t('save')}</button>
                        <button type="button" className="btn btn-danger-custom" onClick={handleDeleteAccount}>{t('deleteAccount')}</button>
                        <button type="button" className="btn btn-secondary" onClick={() => navigate('/customer-dashboard')}>{t('cancel')}</button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default Profile;
