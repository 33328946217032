import React, { useState } from 'react';
import api from '../sharedComponents/api';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/contact', formData);
      if (response.status === 200) {
        alert('Vielen Dank für Ihre Nachricht. Wir werden uns in Kürze bei Ihnen melden.');
      } else {
        alert('Es gab ein Problem bei der Übermittlung Ihrer Nachricht. Bitte versuchen Sie es später erneut.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Es gab ein Problem bei der Übermittlung Ihrer Nachricht. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">Kontaktieren Sie uns</h1>
      <p className="contact-description">
        Bei Fragen, Feedback oder anderen Anliegen senden Sie uns eine E-Mail an: <strong>service@homiego.de</strong> oder füllen Sie das folgende Formular aus. 
        <br /><br />
        Sie können uns beispielsweise bezüglich Fragen zur Registrierung, technischen Problemen oder Feedback zur Plattform kontaktieren. Wir freuen uns darauf, Ihnen zu helfen!
      </p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">E-Mail</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="subject">Betreff</label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Nachricht</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        />

        <button type="submit" className="contact-button">Absenden</button>
      </form>
    </div>
  );
};

export default Contact;
