import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      await api.post(`/logout`, {}); // Authorization header handled by interceptor

      setIsAuthenticated(false);
      setIsAdmin(false);
      localStorage.removeItem('oai');

      navigate('/login');
    } catch (error) {
      console.error("Logout failed", error);
    }
  }, [navigate]);

  const fetchUser = useCallback(async () => {
    try {
      const response = await api.get('/profile'); 
      if (response.status === 200) {
        const { is_admin } = response.data;
        setIsAuthenticated(true);
        setIsAdmin(is_admin);
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      setIsAuthenticated(false);
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('oai');
    
    if (token) {
      fetchUser(); // Fetch user data if token exists
    } else {
      setIsAuthenticated(false);
      setIsAdmin(false);
    }

    const interceptor = api.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('oai');
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [fetchUser, logout]);

  const login = async (email, password) => {
    try {
      const response = await api.post(`/login`, { email, password });
      if (response.status === 200) {
        const { access_token } = response.data;
        localStorage.setItem('oai', access_token);

        await fetchUser(); // Fetch user data immediately after login

        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Login failed:", error);
      return false;
    }
  };

  const register = async (formData) => {
    try {
      const response = await api.post(`/register`, formData);
      return response.status === 201;
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message === "Email already registered") {
        return "Email already registered"; 
      }
      console.error("Registration failed", error);
      return false;
    }
  };

  const resendVerification = async (email) => {
    try {
      const response = await api.post(`/resend_verification`, { email });
      return response.status === 200;
    } catch (error) {
      console.error("Resend verification email failed", error);
      return false;
    }
  };

  const resetPassword = async (email) => {
    try {
      const response = await api.post(`/forgot-password`, { email });
      return response.status === 200;
    } catch (error) {
      console.error("Password reset request failed", error.response?.data);
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, login, register, resendVerification, resetPassword, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
