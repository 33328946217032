import './listings.css';
import React, { useState, useEffect, useCallback } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import Filters from './Filters';
import api from '../sharedComponents/api';

const Listings = () => {
  const { t } = useTranslation();
  const [allListings, setAllListings] = useState([]);
  const [listings, setListings] = useState([]);
  const [error, setError] = useState('');
  const [applying, setApplying] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 480); // Track screen size
  const [filters, setFilters] = useState({
    min_price: '',
    max_price: '',
    min_size: '',
    max_size: '',
    min_rooms: '',
    max_rooms: '',
    wbs: '',
    location: []
  });
  const placeholderImage = process.env.PUBLIC_URL + '/germany-map_2.png';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 480);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const applyFilters = useCallback(() => {
    const minPrice = filters.min_price ? Number(filters.min_price) : null;
    const maxPrice = filters.max_price ? Number(filters.max_price) : null;
    const minSize = filters.min_size ? Number(filters.min_size) : null;
    const maxSize = filters.max_size ? Number(filters.max_size) : null;
    const minRooms = filters.min_rooms ? Number(filters.min_rooms) : null;
    const maxRooms = filters.max_rooms ? Number(filters.max_rooms) : null;

    const hasActiveFilters = minPrice || maxPrice || minSize || maxSize || minRooms || maxRooms || filters.wbs || filters.location.length > 0;

    if (!hasActiveFilters) {
      setListings(allListings);
      setError('');
      return;
    }

    const filteredListings = allListings.filter((listing) => {
      if (minPrice !== null && listing.price < minPrice) return false;
      if (maxPrice !== null && listing.price > maxPrice) return false;
      if (minSize !== null && listing.size < minSize) return false;
      if (maxSize !== null && listing.size > maxSize) return false;
      if (minRooms !== null && listing.rooms < minRooms) return false;
      if (maxRooms !== null && listing.rooms > maxRooms) return false;
      if (filters.wbs === 'true' && !listing.wbs) return false;
      if (filters.wbs === 'false' && listing.wbs) return false;

      if (filters.location.length > 0) {
        const addressLower = listing.address.toLowerCase();
        const matchesLocation = filters.location.some(
          (loc) => addressLower.includes(loc.toLowerCase())
        );
        if (!matchesLocation) return false;
      }

      return true;
    });

    setListings(filteredListings);

    if (filteredListings.length === 0) {
      setError(t('nolistingsfoundwiththesefilters'));
    } else {
      setError('');
    }
  }, [filters, allListings, t]);

  // Utility function to encode data to Base64
const encodeBase64 = (data) => {
  return btoa(JSON.stringify(data));
};

// Utility function to decode data from Base64
const decodeBase64 = (data) => {
  try {
    return JSON.parse(atob(data));
  } catch (e) {
    return null; // Return null if parsing fails
  }
};

  useEffect(() => {
    const fetchListings = async () => {
      setLoading(true);
      try {
        const response = await api.get('/listings');
        if (response.data && response.data.listings) {
          setAllListings(response.data.listings);
          setListings(response.data.listings);
        } else {
          setAllListings([]);
          setListings([]);
        }
        setLoading(false);
      } catch (error) {
        setError(t('errorFetchingListings'));
        setLoading(false);
      }
    };
    fetchListings();
  }, [t]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);



  const getTodayDateString = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format: 'YYYY-MM-DD'
  };

// Function to get the application count from localStorage
const getApplicationCount = () => {
  const today = getTodayDateString();
  const encodedData = localStorage.getItem('app_obf');
  if (encodedData) {
    const { date, count } = decodeBase64(encodedData);
    if (date === today) {
      return count;
    }
  }
  return 0; // If no data or outdated, return 0
};

// Function to increment the application count in localStorage
const incrementApplicationCount = () => {
  const today = getTodayDateString();
  const count = getApplicationCount() + 1;
  const encodedData = encodeBase64({ date: today, count });
  localStorage.setItem('app_obf', encodedData);
};


  const handleApply = async (listing) => {
    if (listing.has_applied) {
      alert(t('alreadyApplied'));
      return;
    }

    const applicationCount = getApplicationCount();
    if (applicationCount >= 10) {
      alert(t('dailyLimitReached'));
      return;
    }

    setApplying(listing.url);
    try {
      const response = await api.post('/apply', { listing_url: listing.url });
      switch (response.data.message) {
        case "already_applied":
          alert(t('alreadyApplied'));
          break;
        case "gesobau_manual_application":
          alert(t('gesobauManual'));
          break;
        case "howoge_manual_application":
          alert(t('howogeManual'));
          break;
        default:
          alert(t('applicationSubmitted'));
          incrementApplicationCount();
          setListings(listings.map(item => item.url === listing.url ? { ...item, has_applied: true } : item));
      }
    } catch (error) {
      alert(t('errorApplyingListing') + `: ${error.response?.data?.message || error.message}`);
    } finally {
      setApplying(null);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">{t('newListings')}</h2>
      <Filters filters={filters} handleFilterChange={handleFilterChange} applyFilters={applyFilters} setFilters={setFilters} />

      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{t('loading')}</span>
          </div>
        </div>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        listings.length > 0 ? (
          <div className="row">
            {listings.map((listing, index) => (
              <div key={index} className={`col-12 ${isMobile ? 'col-md-6' : 'mb-4'}`}>
                <div className={`card h-100 listing-card ${isMobile ? '' : 'd-flex flex-row align-items-center'}`}>
                  {isMobile ? (
                    <>
                      <div className="image-container">
                        <img
                          src={listing.image_url || placeholderImage}
                          className="card-img-top"
                          alt={listing.title}
                          onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage; }}
                        />
                        <img
                          src={listing.website_logo || placeholderImage}
                          alt="Company Logo"
                          className="company-logo-overlay"
                        />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">{listing.title}
                          <a
                              href={listing.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="ms-2"
                            >
                              <i className="fas fa-external-link-alt details-icon"></i>
                          </a>
                        </h5>
                        <p className="card-text">{listing.description}</p>
                        <p className="card-text"><strong>{t('price')}:</strong> {listing.price} €</p>
                        <p className="card-text"><strong>{t('size')}:</strong> {listing.size} m²</p>
                        <p className="card-text"><strong>{t('rooms')}:</strong> {listing.rooms}</p>
                        <button
                          className={`apply-btn mt-2 ${listing.has_applied ? 'disabled-btn' : ''}`}
                          onClick={() => handleApply(listing)}
                          disabled={listing.has_applied || applying === listing.url}
                        >
                          {applying === listing.url ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : listing.has_applied ? t('alreadyApplied') : t('apply')}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="listing-details flex-grow-1">
                        <div className="d-flex align-items-center mb-2">
                          <img src={listing.website_logo || placeholderImage} alt="Company Logo" className="company-logo me-3" />
                          <h5 className="card-title">
                            {listing.title}
                            <a
                              href={listing.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="ms-2"
                            >
                              <i className="fas fa-external-link-alt details-icon"></i>
                            </a>
                          </h5>
                        </div>
                        <p className="card-text">{listing.description}</p>
                        <p className="card-text"><strong>{t('price')}:</strong> {listing.price} €</p>
                        <p className="card-text"><strong>{t('size')}:</strong> {listing.size} m²</p>
                        <p className="card-text"><strong>{t('rooms')}:</strong> {listing.rooms}</p>
                        <p className="card-text"><strong>{t('appliedCount')}:</strong> {listing.applied_count}</p>
                      </div>
                      <div className="listing-image ms-3 text-center">
                        <img
                          src={listing.image_url || placeholderImage}
                          className="img-fluid"
                          alt={listing.title}
                          onError={(e) => { e.target.onerror = null; e.target.src = placeholderImage; }}
                        />
                        <button
                          className={`apply-btn mt-2 ${listing.has_applied ? 'disabled-btn' : ''}`}
                          onClick={() => handleApply(listing)}
                          disabled={listing.has_applied || applying === listing.url}
                        >
                          {applying === listing.url ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          ) : listing.has_applied ? t('alreadyApplied') : t('apply')}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-listings">{t('listingsComingSoon')}</p>
        )
      )}
    </div>
  );
};

export default Listings;
