import React, { useEffect, useContext, useRef, useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './AuthContext';
import './NavBar.css';
import { FaSignInAlt, FaUserPlus, FaUser, FaHome, FaSignOutAlt, FaClipboardList, FaBell, FaShoppingCart, FaUserShield, FaGlobe, FaTasks } from 'react-icons/fa';
import { FlagIcon } from 'react-flag-kit';

const NavBar = () => {
  const { isAuthenticated, logout, isAdmin } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const navbarRef = useRef(null);
  const togglerRef = useRef(null);
  const [activeLink, setActiveLink] = useState(location.pathname);

  const changeLanguage = (lng) => i18n.changeLanguage(lng);

  const closeNavbar = useCallback(() => {
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      togglerRef.current.click();
    }
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      closeNavbar();
    }
  }, [closeNavbar]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const handleLogout = () => {
    logout();
    navigate('/login');
    closeNavbar();
  };

  const handleNavigation = (path) => {
    navigate(path);
    setActiveLink(path);
    closeNavbar();
  };

  const renderAdminLinks = () => (
    <li className="nav-item">
      <button className={`nav-link ${activeLink === '/admin-dashboard' ? 'active-link' : ''}`} onClick={() => handleNavigation('/admin-dashboard')}>
        <FaUserShield className="icon" /> {t('adminDashboard')}
      </button>
    </li>
  );

  const renderCustomerLinks = () => (
    <>
      <li className="nav-item"><button className={`nav-link ${activeLink === '/customer-dashboard' ? 'active-link' : ''}`} onClick={() => handleNavigation('/customer-dashboard')}><FaHome className="icon" /> {t('dashboard')}</button></li>
      <li className="nav-item"><button className={`nav-link ${activeLink === '/preferences' ? 'active-link' : ''}`} onClick={() => handleNavigation('/preferences')}><FaClipboardList className="icon" /> {t('preferences')}</button></li>
      <li className="nav-item"><button className={`nav-link ${activeLink === '/profile' ? 'active-link' : ''}`} onClick={() => handleNavigation('/profile')}><FaUser className="icon" /> {t('profile')}</button></li>
      <li className="nav-item"><button className={`nav-link ${activeLink === '/applied-listings' ? 'active-link' : ''}`} onClick={() => handleNavigation('/applied-listings')}><FaTasks className="icon" /> {t('appliedListings')}</button></li>
      <li className="nav-item"><button className={`nav-link ${activeLink === '/notifications' ? 'active-link' : ''}`} onClick={() => handleNavigation('/notifications')}><FaBell className="icon" /> {t('notifications')}</button></li>
      <li className="nav-item"><button className={`nav-link ${activeLink === '/payment' ? 'active-link' : ''}`} onClick={() => handleNavigation('/payment')}><FaShoppingCart className="icon" /> {t('buyPackages')}</button></li>
    </>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top custom-navbar" ref={navbarRef}>
      <div className="container-fluid">
        <button className="navbar-brand" onClick={() => handleNavigation(isAuthenticated ? (isAdmin ? "/admin-dashboard" : "/customer-dashboard") : "/")}>
          <div className="logo-container">
            <img src="/new-logo-2.png" alt="HomieGo Logo" className="logo" />
            <span className="brand-name">Homie<span className="highlight">Go</span></span>
          </div>
        </button>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" ref={togglerRef}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {!isAuthenticated ? (
              <>
                <li className="nav-item"><button className={`nav-link ${activeLink === '/login' ? 'active-link' : ''}`} onClick={() => handleNavigation('/login')}><FaSignInAlt className="icon" /> {t('login')}</button></li>
                <li className="nav-item"><button className={`nav-link ${activeLink === '/register' ? 'active-link' : ''}`} onClick={() => handleNavigation('/register')}><FaUserPlus className="icon" /> {t('register')}</button></li>
              </>
            ) : (
              <>
                {isAdmin ? renderAdminLinks() : renderCustomerLinks()}
                <li className="nav-item"><button className="nav-link" onClick={handleLogout}><FaSignOutAlt className="icon" /> {t('logout')}</button></li>
              </>
            )}
            <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><FaGlobe className="icon" /> {t('language')}</button>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><button className="dropdown-item" onClick={() => changeLanguage('en')}><FlagIcon code="GB" size={16} className="me-2" /> English</button></li>
                <li><button className="dropdown-item" onClick={() => changeLanguage('de')}><FlagIcon code="DE" size={16} className="me-2" /> Deutsch</button></li>
                <li><button className="dropdown-item" onClick={() => changeLanguage('ar')}><FlagIcon code="SA" size={16} className="me-2" /> العربية</button></li>
                <li><button className="dropdown-item" onClick={() => changeLanguage('uk')}><FlagIcon code="UA" size={16} className="me-2" /> Українська</button></li>
                <li><button className="dropdown-item" onClick={() => changeLanguage('es')}><FlagIcon code="ES" size={16} className="me-2" /> Español</button></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
