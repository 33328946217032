import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import api from '../sharedComponents/api';

const AdminListings = () => {
    const { t } = useTranslation();
    const [listings, setListings] = useState([]);
    const [websites, setWebsites] = useState([]);
    const [selectedWebsite, setSelectedWebsite] = useState('');
    const [wbsFilter, setWbsFilter] = useState(false);
    const [expiredFilter, setExpiredFilter] = useState(false);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [minRooms, setMinRooms] = useState('');
    const [maxRooms, setMaxRooms] = useState('');
    const [listingsByWebsite, setListingsByWebsite] = useState({});
    const [totalListings, setTotalListings] = useState(0);
    const [error, setError] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedListing, setSelectedListing] = useState(null);
    const [dummyData, setDummyData] = useState({
        name: '',
        email: '',
        first_name: '',
        last_name: '',
        birth_date: '',
        gender: '',
        phone_number: '',
        number_of_persons: '',
        message: '',
        ishandycap: false,
        haswbs: false,
        wbs_expire_date: '',
        wbs_number_of_rooms: '',
        wbs_type: ''
    });
    const [sortCriteria, setSortCriteria] = useState('applied_counter'); 
    const [sortDirection, setSortDirection] = useState('desc'); 

    useEffect(() => {
        const fetchListings = async () => {
            try {
                const response = await api.get(`/admin/listings`);
                const websitesResponse = await api.get(`/admin/websites`);

                if (Array.isArray(response.data)) {
                    setListings(response.data);
                    setWebsites(websitesResponse.data);
                    setTotalListings(response.data.length);
                    calculateListingsByWebsite(response.data);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setListings([]);
                }
            } catch (error) {
                console.error(t('errorFetchingListings'), error);
                setError(t('errorFetchingListings'));
            }
        };
    
        fetchListings();
    }, [t]);

    const calculateListingsByWebsite = (listings) => {
        const countByWebsite = {};
        listings.forEach((listing) => {
            const website = listing.website_name;
            if (!countByWebsite[website]) {
                countByWebsite[website] = 0;
            }
            countByWebsite[website] += 1;
        });
        setListingsByWebsite(countByWebsite);
    };

    const handleSort = (criteria) => {
        if (sortCriteria === criteria) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCriteria(criteria);
            setSortDirection('asc');
        }
    };

    const handleGoToListing = (url) => {
        window.open(url, '_blank');
    };

    const handleApplyClick = (listing) => {
        setSelectedListing(listing);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setDummyData({
            name: '',
            email: '',
            first_name: '',
            last_name: '',
            birth_date: '',
            gender: '',
            phone_number: '',
            number_of_persons: '',
            message: '',
            ishandycap: false,
            haswbs: false,
            wbs_expire_date: '',
            wbs_number_of_rooms: '',
            wbs_type: ''
        });
    };

    const handleDummyDataChange = (e) => {
        const { name, value } = e.target;
        setDummyData((prev) => ({ ...prev, [name]: value }));
    };

    const handleApplySubmit = async () => {
        if (!selectedListing) return;

        try {
            const response = await api.post(`/admin/apply`, {
                listing_url: selectedListing.url,
                ...dummyData,
            });

            if (response.status === 200) {
                console.log(t('applicationSuccessful'));
            } else {
                console.error(t('applicationFailed'), response.data);
            }
        } catch (error) {
            console.error(t('errorDuringApplication'), error);
            setError(t('errorDuringApplication'));
        } finally {
            handleDialogClose();
        }
    };

    const filteredListings = listings.filter((listing) => {
        const matchesWebsite = selectedWebsite === '' || listing.website_name === selectedWebsite;
        const matchesWbs = !wbsFilter || listing.wbs;
        const matchexpired = !expiredFilter || listing.is_expired;
        const matchesMinPrice = minPrice === '' || listing.price >= parseFloat(minPrice);
        const matchesMaxPrice = maxPrice === '' || listing.price <= parseFloat(maxPrice);
        const matchesMinRooms = minRooms === '' || listing.rooms >= parseInt(minRooms, 10);
        const matchesMaxRooms = maxRooms === '' || listing.rooms <= parseInt(maxRooms, 10);
        return matchesWebsite && matchesWbs && matchexpired && matchesMinPrice && matchesMaxPrice && matchesMinRooms && matchesMaxRooms;
    });

    const sortedListings = filteredListings.sort((a, b) => {
        let comparison = 0;

        if (sortCriteria === 'applied_counter') {
            comparison = a.applied_count - b.applied_count;
        } else if (sortCriteria === 'posted_date') {
            comparison = new Date(a.posted_date) - new Date(b.posted_date);
        }

        return sortDirection === 'asc' ? comparison : -comparison;
    });

    return (
        <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                {t('adminListings')}
            </Typography>

            {error && <Typography variant="h6" color="error">{error}</Typography>}

            <Typography variant="h6" component="h2" gutterBottom>
                {t('totalListings')}: {totalListings}
            </Typography>

            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                <InputLabel>{t('selectWebsite')}</InputLabel>
                <Select
                    value={selectedWebsite}
                    onChange={(e) => setSelectedWebsite(e.target.value)}
                    label={t('selectWebsite')}
                >
                    <MenuItem value="">{t('allWebsites')}</MenuItem>
                    {websites.map((website) => (
                        <MenuItem key={website.id} value={website.name}>
                            {website.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={wbsFilter}
                        onChange={() => setWbsFilter(!wbsFilter)}
                        color="primary"
                    />
                }
                label={t('filterWbs')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={expiredFilter}
                        onChange={() => setExpiredFilter(!expiredFilter)}
                        color="primary"
                    />
                }
                label={t('filterexpired')}
            />

            <TextField
                label={t('minPrice')}
                type="number"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />
            <TextField
                label={t('maxPrice')}
                type="number"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />

            <TextField
                label={t('minRooms')}
                type="number"
                value={minRooms}
                onChange={(e) => setMinRooms(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />
            <TextField
                label={t('maxRooms')}
                type="number"
                value={maxRooms}
                onChange={(e) => setMaxRooms(e.target.value)}
                variant="outlined"
                sx={{ mb: 3, mr: 2 }}
            />

            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                <InputLabel>{t('sortBy')}</InputLabel>
                <Select
                    value={sortCriteria}
                    onChange={(e) => setSortCriteria(e.target.value)}
                    label={t('sortBy')}
                >
                    <MenuItem value="applied_counter">{t('sortByAppliedCount')}</MenuItem>
                    <MenuItem value="posted_date">{t('sortByPostedDate')}</MenuItem>
                </Select>
            </FormControl>

            <Button 
                variant="contained" 
                onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
                sx={{ mb: 3 }}
            >
                {sortDirection === 'asc' ? t('sortDescending') : t('sortAscending')}
            </Button>

            {Object.keys(listingsByWebsite).length > 0 ? (
                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {t('listingsByWebsite')}
                    </Typography>
                    {Object.entries(listingsByWebsite).map(([website, count]) => (
                        <Typography key={website}>
                            {t('website')}: {website} - {t('listingsCount')}: {count}
                        </Typography>
                    ))}
                </Paper>
            ) : null}

            {Array.isArray(sortedListings) && sortedListings.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => handleSort('id')}>
                                    {t('id')}
                                    {sortCriteria === 'id' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell>
                                {/* <TableCell onClick={() => handleSort('title')}>
                                    {t('title')}
                                    {sortCriteria === 'title' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell> */}
                                <TableCell onClick={() => handleSort('price')}>
                                    {t('price')}
                                    {sortCriteria === 'price' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell>
                                <TableCell onClick={() => handleSort('size')}>
                                    {t('size')}
                                    {sortCriteria === 'size' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell>
                                <TableCell onClick={() => handleSort('rooms')}>
                                    {t('rooms')}
                                    {sortCriteria === 'rooms' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell>
                                <TableCell>{t('address')}</TableCell>
                                <TableCell>{t('description')}</TableCell>
                                <TableCell onClick={() => handleSort('wbs')}>
                                    {t('wbs')}
                                    {sortCriteria === 'wbs' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell>
                                <TableCell>{t('website')}</TableCell>
                                <TableCell onClick={() => handleSort('posted_date')}>
                                    {t('postedDate')}
                                    {sortCriteria === 'posted_date' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell>
                                <TableCell onClick={() => handleSort('applied_counter')}>
                                    {t('applied_counter')}
                                    {sortCriteria === 'applied_counter' && (sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                                </TableCell>
                                <TableCell>{t('actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedListings.map((listing) => (
                                <TableRow key={listing.id}>
                                    <TableCell>{listing.id}</TableCell>
                                    {/* <TableCell>{listing.title}</TableCell> */}
                                    <TableCell>{listing.price}</TableCell>
                                    <TableCell>{listing.size}</TableCell>
                                    <TableCell>{listing.rooms}</TableCell>
                                    <TableCell>{listing.address}</TableCell>
                                    <TableCell>{listing.description}</TableCell>
                                    <TableCell>{listing.wbs ? t('yes') : t('no')}</TableCell>
                                    <TableCell>{listing.website_name}</TableCell>
                                    <TableCell>{new Date(listing.posted_date).toLocaleDateString()}</TableCell>
                                    <TableCell>{listing.applied_count}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleGoToListing(listing.url)}
                                            sx={{ mr: 1 }}
                                        >
                                            {t('goToListing')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleApplyClick(listing)}
                                        >
                                            {t('apply')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 2 }}>
                    {t('noListingsAvailable')}
                </Typography>
            )}

            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>{t('applyToListing')}</DialogTitle>
                <DialogContent>
                    <TextField
                        label={t('name')}
                        name="name"
                        value={dummyData.name}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('email')}
                        name="email"
                        value={dummyData.email}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('firstName')}
                        name="first_name"
                        value={dummyData.first_name}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('lastName')}
                        name="last_name"
                        value={dummyData.last_name}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('birthDate')}
                        name="birth_date"
                        value={dummyData.birth_date}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label={t('gender')}
                        name="gender"
                        value={dummyData.gender}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('phoneNumber')}
                        name="phone_number"
                        value={dummyData.phone_number}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('numberOfPersons')}
                        name="number_of_persons"
                        value={dummyData.number_of_persons}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('message')}
                        name="message"
                        value={dummyData.message}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dummyData.ishandycap}
                                onChange={(e) => setDummyData((prev) => ({ ...prev, ishandycap: e.target.checked }))}
                                name="ishandycap"
                                color="primary"
                            />
                        }
                        label={t('isHandycap')}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={dummyData.haswbs}
                                onChange={(e) => setDummyData((prev) => ({ ...prev, haswbs: e.target.checked }))}
                                name="haswbs"
                                color="primary"
                            />
                        }
                        label={t('hasWBS')}
                    />
                    <TextField
                        label={t('wbsExpireDate')}
                        name="wbs_expire_date"
                        value={dummyData.wbs_expire_date}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label={t('wbsNumberOfRooms')}
                        name="wbs_number_of_rooms"
                        value={dummyData.wbs_number_of_rooms}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                    <TextField
                        label={t('wbsType')}
                        name="wbs_type"
                        value={dummyData.wbs_type}
                        onChange={handleDummyDataChange}
                        fullWidth
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleApplySubmit} color="secondary" variant="contained">
                        {t('apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default AdminListings;
