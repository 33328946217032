import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'; // Import Form for Form.Check
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Listings from './Listings';
import '../App.css';

const CustomerDashboard = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true); // Initially open
  const [dontShowAgain, setDontShowAgain] = useState(false); // Track "Don't show again" checkbox
  const navigate = useNavigate();

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem('hide_', 'true'); // Save in localStorage if checked
    }
    setOpen(false);
  };

  const handleGoToPreferences = () => {
    if (dontShowAgain) {
      localStorage.setItem('hide_', 'true'); // Save in localStorage if checked
    }
    navigate('/preferences');
    setOpen(false);
  };

  const handleCheckboxChange = (e) => {
    setDontShowAgain(e.target.checked);
  };

  useEffect(() => {
    if (localStorage.getItem('hide_') === 'true') {
      setOpen(false);
    }
  }, [t]);

  return (
    <div className="container">
      {/* Listings component should always be displayed */}
      <Listings />

      {/* Popup modal for preferences */}
      <Modal show={open} onhide_={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('personalizeYourExperience')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('fillOutPreferences')}</p>
          <Form.Check
            type="checkbox"
            label={t("dontShowAgain")}
            checked={dontShowAgain}
            onChange={handleCheckboxChange}
            className="mb-3"
          />
        </Modal.Body>
        <Modal.Footer className="d-flex flex-column flex-md-row">
          <Button
            variant="primary"
            className="w-100 w-md-auto mb-2 mb-md-0"
            onClick={handleGoToPreferences}
          >
            {t('viewEditPreferences')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerDashboard;
