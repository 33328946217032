import React, { useEffect, useState } from 'react';
import './PaymentPage.css';
import { FaWhatsapp, FaRobot } from 'react-icons/fa';
import api from '../sharedComponents/api';

const PaymentPage = () => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [balances, setBalances] = useState(null);
    const [payments, setPayments] = useState([]);
    const [discountCode, setDiscountCode] = useState('');
    const [discount, setDiscount] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await api.get('/users/me/payments');
                setBalances(response.data.balances);
                setPayments(response.data.payments);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const packages = [
        { id: 1, type: 'notification_30', description: '30 listings via WhatsApp', price: 7.5, icon: <FaWhatsapp /> },
        { id: 2, type: 'auto_apply_30', description: '30 automatic applications', price: 13.5, icon: <FaRobot /> },
        { id: 3, type: 'notification_50', description: '50 listings via WhatsApp', price: 10, icon: <FaWhatsapp /> },
        { id: 4, type: 'auto_apply_50', description: '50 automatic applications', price: 15, icon: <FaRobot /> }
    ];

    const handlePackageSelection = (pkg) => {
        setSelectedPackage(pkg);
        setDiscount(null); // Reset discount when a new package is selected
    };

    const validateDiscountCode = async () => {
        try {
            const response = await api.post('/discounts/validate', { code: discountCode });
            setDiscount(response.data);
            alert('Discount code applied successfully!');
        } catch (error) {
            console.error('Error validating discount code:', error);
            alert('Invalid or expired discount code.');
        }
    };

    const handlePayment = async () => {
        if (!selectedPackage) {
            alert('Please select a package before proceeding.');
            return;
        }
        setLoading(true);
        try {
            // Include discount code if available
            const response = await api.post('/paypal/create-payment', {
                package_type: selectedPackage.type,
                listings_count: selectedPackage.type.includes('_30') ? 30 : 50,  // Correct count based on selected package
                discount_code: discountCode // Include the discount code in the request
            });
    
            // Check if payment is needed
            if (response.data.approvalUrl) {
                // Redirect to PayPal approval URL if payment is needed
                window.location.href = response.data.approvalUrl;
            } else if (response.data.message) {
                // If payment is not needed, show success message
                alert(response.data.message);
                // Optionally, update the user's balance
                if (response.data.newBalances) {
                    setBalances(response.data.newBalances);
                }
                // Refresh the page to reflect updated balances and data
                window.location.reload();
            }
        }  catch (error) {
            console.error('Error creating PayPal payment:', error);
            alert('Error initiating payment. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="payment-container mt-4">
            <h2 className="payment-title">Your Balances</h2>
            {balances ? (
                <div className="balances">
                    <p className="balance-item">
                        <FaWhatsapp className="balance-icon" /> <strong>Notification Balance:</strong> {balances.notification_balance}
                    </p>
                    <p className="balance-item">
                        <FaRobot className="balance-icon" /> <strong>Auto Apply Balance:</strong> {balances.auto_apply_balance}
                    </p>
                </div>
            ) : (
                <p>Loading balances...</p>
            )}
            <h2 className="payment-title">Payment History</h2>
            {payments.length > 0 ? (
                <ul className="payment-history">
                    {payments.map((payment) => (
                        <li key={payment.id}>
                            {payment.package_type} - €{payment.amount} - {payment.payment_date} ({payment.payment_status})
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No payments found.</p>
            )}
            <h2 className="payment-title">Select a Package</h2>
            <div className="package-row">
                {packages.map((pkg) => (
                    <div
                        key={pkg.id}
                        className={`package-card ${selectedPackage?.id === pkg.id ? 'selected' : ''}`}
                        onClick={() => handlePackageSelection(pkg)}
                    >
                        <div className="package-content">
                            <div className="package-icon">{pkg.icon}</div>
                            <h5 className="package-title">{pkg.description}</h5>
                            <p className="package-price">Price: €{pkg.price}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="discount-section">
                <input
                    type="text"
                    className="discount-input"
                    placeholder="Enter discount code"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                />
                <button className="discount-button" onClick={validateDiscountCode}>
                    Apply Discount
                </button>
                {discount && (
                    <p className="discount-info">
                        Discount Applied: {discount.type === 'percentage' ? `${discount.value}%` : `€${discount.value}`}
                    </p>
                )}
            </div>
            <button
                className="payment-button"
                onClick={handlePayment}
                disabled={!selectedPackage || loading}
            >
                {loading ? 'Processing...' : 'Proceed to PayPal'}
            </button>
        </div>
    );
};

export default PaymentPage;